/* @import-normalize; */
@import 'https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css';
@import './spinner.scss';

@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: normal;
  src: local('Product Sans'),
    url('../fonts/ProductSans-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Google Sans';
  // font-style: normal;
  // font-weight: 300;
  src: local('Google Sans'), url('../fonts/Google\ Sans.woff2') format('woff2');
}

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ede7f6;
}

#root {
  width: 100vw;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body > #root > div {
  height: 100vh;
}

ol {
  margin: 0;
  padding-left: 1.5em;
}
ul {
  margin: 0;
  padding-left: 1.5em;
}
